<template>
  <div class="search-list">
    <el-tabs v-model="activeName" class="search-list-tabs">
      <el-tab-pane
        v-for="item of tabsList"
        :key="item.key"
        :label="item.name"
        :name="item.key"
      />
    </el-tabs>
    <div class="list-content" v-loading="loading">
      <div v-for="(item, pIndex) of list" :key="item.type">
        <div class="gre-type">
          <span>{{ item.cate_name }}</span>
        </div>
        <ul class="list">
          <li
            v-for="(items, index) of item.cate_list"
            :key="`${items.gre_id}-${items.question_id}`"
          >
            <div class="top">
              <span class="name" @click="goDetail(items)"
                >{{ index + 1 }}. {{ items.title }}</span
              >
              <span class="idTag">#{{ items.gre_id }}</span>
              <span class="tagList">
                <span
                  class="tagItem"
                  v-for="tagItem of items.tag_list"
                  :key="`tagItem-${tagItem}`"
                  :style="{
                    background: colorMap[tagItem] && colorMap[tagItem].bgColor,
                    color: colorMap[tagItem] && colorMap[tagItem].color
                  }"
                >
                  {{ tagItem }}
                </span>
              </span>
              <div class="right-tag">
                <span class="detailTag" v-if="items.has_detail"
                  ><i class="f-icon icon-Videocamera" />详解</span
                >
                <span class="idTag">{{ items.try_tips }}</span>
                <span
                  class="text-button"
                  :class="{ disabled: items.has_exam }"
                  @click="handlePass(items, pIndex, index)"
                  >考过({{ items.had_exam_cnt }})</span
                >
                <i
                  class="f-icon btn-item-mark"
                  :class="[items.has_like ? 'icon-star-c active' : 'icon-star']"
                  @click="handleCollect(items, pIndex, index)"
                />
              </div>
            </div>
            <div class="bottom">
              <div
                class="bottom-content"
                v-html="showHighlight(items.content)"
              />
            </div>
          </li>
        </ul>
      </div>
      <Empty v-if="!loading && list && !list.length" />
    </div>
    <PassDialog
      v-if="showPassDialog"
      :id="current.greId"
      :currentId="current.id"
      :isPractice="current.isPractice"
      @successPass="handleCheckOk"
      :visible="showPassDialog"
      @closeDialog="handlePassDialog"
    />
  </div>
</template>
<script>
import { apiSearchPc, apiWebDelCollect, apiWebCollect } from '@/api/api.js'
import Empty from '@/components/ExercisesEmpty.vue'
import PassDialog from '@/components/ExercisesContent/components/pass-dialog.vue'
import i18n from '@/i18n/i18n'

const TABS = [
  { key: '0', name: i18n.t('search.all') },
  { key: '25', name: i18n.t('search.speak') },
  { key: '27', name: i18n.t('search.write') },
  { key: '26', name: i18n.t('search.read') },
  { key: '24', name: i18n.t('search.listen') }
]
const TAG_COLOR_MAP = {
  机经题: {
    bgColor: '#C1E6FE',
    color: '#345D7F'
  },
  练习题: {
    bgColor: '#AAF9E6',
    color: '#006D5D'
  },
  超高频: {
    bgColor: '#FFD2DD',
    color: '#AD1625'
  },
  高频: {
    bgColor: '#FFD2DD',
    color: '#AD1625'
  },
  本月高频: {
    bgColor: '#FFD2DD',
    color: '#AD1625'
  },
  老题新考: {
    bgColor: '#FED695',
    color: '#A3382A'
  },
  新题: {
    bgColor: '#FED695',
    color: '#A3382A'
  }
}

export default {
  components: {
    Empty,
    PassDialog
  },
  data() {
    return {
      keyword: '',
      activeName: '0',
      tabsList: TABS,
      colorMap: TAG_COLOR_MAP,
      loading: false,
      list: [],
      collectedLoading: false,
      showPassDialog: false,
      current: {}
    }
  },
  mounted() {
    const { keyword: routerKeyword } = this.$route.query || {}
    this.keyword = routerKeyword || ''
  },
  watch: {
    $route(to) {
      if (to.query.keyword !== this.keyword) {
        this.keyword = to.query.keyword || ''
      }
    },
    keyword(val) {
      if (val) {
        this.getSearchList()
      }
    },
    activeName() {
      this.getSearchList()
    }
  },
  methods: {
    showHighlight(content) {
      if (!content) {
        return ''
      }
      return content.replace(
        this.keyword,
        `<span class="highlight">${this.keyword}</span>`
      )
    },
    handlePassDialog() {
      this.showPassDialog = !this.showPassDialog
    },
    handleCheckOk() {
      const { pIndex, index } = this.current
      this.list[pIndex].cate_list[index].had_exam_cnt =
        this.list[pIndex].cate_list[index].had_exam_cnt + 1
      this.list[pIndex].cate_list[index].has_exam = true
      this.$layer.msg("<div class='success-icon'></div><div>确认考过成功</div>")
    },
    handlePass(item, pIndex, index) {
      if (item.is_gre === 2) {
        this.current = {
          id: item.question_id,
          isPractice: true,
          greId: item.gre_id,
          pIndex,
          index
        }
      } else {
        this.current = {
          id: item.gre_id,
          isPractice: false,
          greId: item.gre_id,
          pIndex,
          index
        }
      }
      this.showPassDialog = true
    },
    goDetail(item) {
      if (item.is_gre === 2) {
        this.$router.push({
          name: 'Exercises',
          query: {
            clid: item.cate_id,
            practiceId: item.question_id,
            from: 'search'
          }
        })
      } else {
        this.$router.push({
          name: 'Exercises',
          query: { clid: item.cate_id, greId: item.gre_id, from: 'search' }
        })
      }
    },
    getSearchList() {
      this.loading = true
      apiSearchPc({
        type: Number(this.activeName),
        keyword: this.keyword
      })
        .then((res) => {
          this.list = res.data.list
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleCollect(item, pIndex, index) {
      if (this.collectedLoading) {
        return
      }
      const id = item.is_gre === 2 ? item.question_id : item.gre_id
      const type = item.is_gre === 2 ? 'practice' : 'gremat'
      this.collectedLoading = true
      if (item.has_like) {
        apiWebDelCollect({
          aid: id,
          paramerid: id,
          type
        })
          .then((res) => {
            if (res.code != 200) {
              return
            }
            this.$layer.msg(
              "<div class='success-icon'></div><div>已取消收藏</div>"
            )
            this.list[pIndex].cate_list[index].has_like = !item.has_like
          })
          .finally(() => {
            this.collectedLoading = false
          })
      } else {
        apiWebCollect({
          aid: id,
          paramerid: id,
          type
        })
          .then((res) => {
            if (res.code != 200) {
              return
            }
            this.$layer.msg("<div class='success-icon'></div><div>已收藏</div>")
            this.list[pIndex].cate_list[index].has_like = !item.has_like
          })
          .finally(() => {
            this.collectedLoading = false
          })
      }
    }
  }
}
</script>
<style lang="less">
.search-list {
  padding: 30px 0;
  background: #fff;
  .highlight {
    background: #ffff00;
    color: #000;
  }
  .el-tabs__item {
    height: 44px;
    font-size: 18px;
  }
  .el-tabs__header {
    margin-bottom: 0;
  }
  .gre-type {
    margin: 15px 0 0;
    span {
      padding: 0 19px;
      height: 20px;
      background: #ffffff;
      border: 1px solid #ff606d;
      border-radius: 5px;
      font-size: 14px;
      color: #ff606d;
    }
  }
  .tagItem {
    height: 20px;
    margin: 5px 6px 5px 0;
    padding: 0 7px;
    border-radius: 15px;
    box-sizing: border-box;
  }
  .tagList {
    display: flex;
    flex-wrap: wrap;
    margin-left: 12px;
    flex-shrink: 0;
  }
  .list-content {
    min-height: 200px;
  }
  .list {
    li {
      margin-top: 15px;
      font-size: 12px;
      line-height: 20px;
      span {
        display: inline-block;
      }
      .top {
        display: flex;
        align-items: center;
      }
      .bottom {
        margin-left: 15px;
        border-bottom: 1px solid #ebebf2;
      }
      .bottom-content {
        margin: 10px 0;
        font-size: 16px;
        line-height: 26px;
        color: #a4afb7;
        overflow: hidden;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        box-orient: vertical;
        display: -webkit-box;
      }
    }
  }
  .name {
    color: #1f1f40;
    font-size: 14px;
    margin-right: 17px;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
  }
  .idTag {
    margin: 5px 0;
    flex-shrink: 0;
    height: 20px;
    padding: 0 10px;
    color: #a4afb7;
    background: #ebebf2;
    border-radius: 15px;
  }
  .right-tag {
    margin-left: auto;
    flex-shrink: 0;
  }
  .detailTag {
    margin: 5px;
    flex-shrink: 0;
    font-weight: 300;
    height: 20px;
    padding: 0 7px;
    color: #ffffff;
    background: #c1e6fe;
    border-radius: 15px;
    i {
      font-size: 12px;
      margin-right: 1px;
    }
  }
  .text-button {
    height: 18px;
    padding: 0 10px;
    margin-left: 5px;
    background: #ffffff;
    border: 1px solid #c7cade;
    border-radius: 5px;
    font-size: 12px;
    line-height: 18px;
    color: rgba(31, 31, 64, 0.8);
    cursor: pointer;
    &:hover {
      color: #3b86ff;
      background: #ecf3ff;
      border-color: #3b86ff;
    }
    &.disabled {
      color: #c0c4cc;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
  .btn-item-mark {
    color: #c7cade;
    cursor: pointer;
    vertical-align: middle;
    margin-left: 9px;
    &.active {
      color: #ffc06a;
    }
  }
}
</style>
