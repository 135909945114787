<template>
  <el-dialog
    width="786px"
    :visible.sync="visible"
    class="pass-dialog"
    :before-close="handleClose"
  >
    <div class="title">{{ i18n.Pra.Fill }}</div>
    <div class="tips-text">{{ i18n.Pra.text3 }}</div>
    <el-form
      ref="passForm"
      :label-position="'top'"
      label-width="80px"
      :model="info"
      class="form-frame"
    >
      <el-form-item prop="month">
        <div slot="label">
          {{ i18n.Pra.test4
          }}<span class="red">（{{ i18n.Pra.Required }}）</span>
        </div>
        <el-row :gutter="60">
          <el-col :span="7">
            <el-form-item
              prop="year"
              :rules="[{ required: true, message: i18n.Pra.Please }]"
            >
              <el-select
                v-model="info.year"
                :placeholder="i18n.Pra.text5"
                popper-class="pass-select-popper"
              >
                <el-option
                  v-for="item of yearOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              prop="month"
              :rules="[{ required: true, message: i18n.Pra.text6 }]"
            >
              <el-select
                v-model="info.month"
                :placeholder="i18n.Pra.text6"
                popper-class="pass-select-popper"
              >
                <el-option
                  v-for="item of monthOptiions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="i18n.Pra.text7">
        <div class="next-select" v-if="last">
          {{ i18n.Pra.text8
          }}<span class="last-btn" @click="setLast">{{ last.last_point }}</span>
        </div>
        <el-select
          v-model="info.country"
          :placeholder="i18n.Pra.text9"
          @change="change(1)"
          popper-class="pass-select-popper"
        >
          <el-option
            v-for="item of regionOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select
          v-model="info.city"
          :placeholder="i18n.Pra.text10"
          @change="change(2)"
          popper-class="pass-select-popper"
        >
          <el-option
            v-for="item of cityOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select
          v-model="info.point"
          :placeholder="i18n.Pra.text11"
          class="point-select"
          popper-class="pass-select-popper"
        >
          <el-option
            v-for="item of pointOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="i18n.Pra.memory">
        <el-input
          type="textarea"
          :resize="'none'"
          :rows="8"
          :placeholder="''"
          v-model="info.remark"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">{{i18n.Pra.determine}}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { apiGetpoint, apiExamLastPoint, apiExamAdd } from '@/api/api.js'

const MONTH_LIST = [
  {
    label: 1,
    value: '01',
  },
  {
    label: 2,
    value: '02',
  },
  {
    label: 3,
    value: '03',
  },
  {
    label: 4,
    value: '04',
  },
  {
    label: 5,
    value: '05',
  },
  {
    label: 6,
    value: '06',
  },
  {
    label: 7,
    value: '07',
  },
  {
    label: 8,
    value: '08',
  },
  {
    label: 9,
    value: '09',
  },
  {
    label: 10,
    value: '10',
  },
  {
    label: 11,
    value: '11',
  },
  {
    label: 12,
    value: '12',
  },
]
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    id: null,
    currentId: null,
    isPractice: null,
  },
  data() {
    const d = new Date()
    const y = d.getFullYear()
    return {
      info: { year: y },
      monthOptiions: MONTH_LIST,
      regionOptions: [],
      loading: false,
      last: null,
      yearOptions: [y - 1, y],
    }
  },
  created() {
    this.getGetpoint()
    this.getLastPoint()
  },
  computed: {
    cityOption() {
      const city =
        this.regionOptions.find((item) => item.value === this.info.country) ||
        {}
      return city.children || []
    },
    pointOption() {
      const point =
        this.cityOption.find((item) => item.value === this.info.city) || {}
      return point.children
        ? [{ label: "I don't remember", pid: 0, value: 0 }, ...point.children]
        : []
    },
    i18n() {
      return this.$t('messages')
    },
  },
  methods: {
    submit() {
      this.$refs.passForm.validate((valid) => {
        if (valid) {
          if (this.loading) {
            return
          }
          const info = {
            exam_city: this.info.city || 0,
            exam_country: this.info.country || 0,
            exam_point: this.info.point || 0,
            gre_id: this.id,
            question_id: this.isPractice ? this.currentId : 0,
            remark: this.info.remark,
            exam_date: `${this.info.year}-${this.info.month}`,
          }
          this.loading = true
          apiExamAdd(info).then((res) => {
            this.loading = false
            if (res.code != 200) {
              return
            }
            this.handleClose()
            this.$emit('successPass')
          })
        } else {
          return false
        }
      })
    },
    change(index) {
      if (index === 1 && this.info.city) {
        this.info.city = ''
        this.info.point = ''
      } else if (index === 2 && this.info.point) {
        this.info.point = ''
      }
    },
    getGetpoint() {
      apiGetpoint().then((res) => {
        this.regionOptions = res.data
      })
    },
    getLastPoint() {
      apiExamLastPoint().then((res) => {
        this.last = res.data
      })
    },
    setLast() {
      if (!this.last) {
        return
      }
      this.info = {
        ...this.info,
        city: this.last.last_city_id,
        country: this.last.last_country_id,
        point: this.last.last_point_id,
      }
    },
    handleClose() {
      this.$emit('closeDialog')
    },
  },
}
</script>

<style lang='less'>
.pass-select-popper {
  margin-top: 0px !important;
  color: #000000;
  .popper__arrow {
    display: none;
  }
  .el-select-dropdown__list {
    padding: 0;
  }
  .el-select-dropdown__item {
    height: 30px;
    line-height: 30px;
  }
  .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    background: #e6f7ff;
    color: #000000;
  }
  .el-select-dropdown__item.selected {
    color: #000000;
    font-weight: normal;
  }
}
.pass-dialog {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #1f1f40;
  .last-btn {
    cursor: pointer;
  }
  .el-dialog {
    border-radius: 10px;
  }
  .el-input__inner {
    height: 32px;
    width: 224px;
  }
  .el-select {
    margin-right: 12px;
    &.point-select {
      margin-right: 0;
      .el-input__inner {
        height: 32px;
        width: 270px;
      }
    }
  }
  .el-form-item.is-required:not(.is-no-asterisk)
    .el-form-item__label-wrap
    > .el-form-item__label:before,
  .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
    content: '';
  }
  .el-select .el-input.is-focus .el-input__inner,
  .el-select .el-input__inner:focus,
  .el-textarea__inner:focus {
    border: 1px solid #40a9ff;
  }
  .red {
    color: #ff606d;
  }
  .el-dialog__body {
    margin-top: -30px;
  }
  .el-form-item__label {
    padding: 0;
    line-height: 30px;
  }
  .tips-text {
    margin-bottom: 24px;
  }
  .next-select {
    position: absolute;
    top: -34px;
    right: 6px;
    span {
      color: #3b86ff;
    }
  }
  .title {
    padding-bottom: 8px;
    margin-bottom: 20px;
    color: #a4afb7;
    font-size: 14px;
    border-bottom: 1px solid #ebebf2;
  }
}
</style>
